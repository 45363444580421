<template>
  <div class="app-container" v-loading="listLoading">
    <div class="top_box">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline account-top"
      >
        <el-form-item label="标签 :">
          <el-select v-model="queryForm.tag" clearable>
            <el-option
              v-for="item in tagsList"
              :key="item.id"
              :label="item.tag"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属用户 :" v-if="userInfo.is_staff">
          <el-input
            v-model="queryForm.owner"
            placeholder="所属用户ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <SingleMessage
          v-for="(item, index) in list"
          :key="index"
          :singleInfo="item.message"
          :tag="item.tag"
          :id="item.id"
          @reciveVal="reciveVal"
          :detailType="true"
          @updateFun="updateFun"
          tagType="msg"
        />
        <div class="content-no" v-if="list.length == 0">
          暂无数据
        </div>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getMessageTagList, getTagAll } from '@/api/tagged'
import { getManagerStatus } from '@/utils/manager'
import SingleMessage from './components/SingleMessage.vue'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'MessageTag',
  mixins: [cacheMixin],
  components: {
    SingleMessage
  },
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: [],
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      queryForm: {
        page_index: 1,
        page_size: 10,
        owner: '',
        tag: this.$route.query.tag
      },
      tagsList: []
    }
  },
  created() {
    this.fetchData()
    this.getTagsList()
  },
  methods: {
    // 获得所有标签
    async getTagsList() {
      const res = await getTagAll()
      this.tagsList = res
    },
    async fetchData() {
      this.listLoading = true
      const res = await getMessageTagList(this.queryForm)
      // console.log('4', res)
      this.count = res.count
      this.list = res.items

      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    reciveVal(val) {
      if (val) {
        this.fetchData()
      }
    },
    updateFun() {
      this.fetchData()
    }
  }
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(value) {
  //       if (value.query.tag) {
  //         this.query.tag = value.query.tag
  //         this.handleSearch()
  //       }
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0px;
    .el-input {
      padding: 0 15px;
      width: 260px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    ::v-deep span.el-input__prefix {
      left: 17px;
    }
  }
}
.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  // height: calc(100vh - 228px);

  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 35px);
    overflow: auto;
  }
}
.content-no {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #949698;
}
</style>
